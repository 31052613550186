import * as Sentry from '@sentry/gatsby'

Sentry.init({
  dsn: 'https://3f483fb63448c630c95f64d04829ad42@o4506178346680320.ingest.us.sentry.io/4507542105489408',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})
